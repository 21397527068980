import { Component, OnInit } from '@angular/core';
import {ContentService} from "../../../service/content.service";

@Component({
  selector: 'app-impressum',
  templateUrl: './impressum.component.html',
  styleUrls: ['./impressum.component.scss']
})
export class ImpressumComponent implements OnInit {
  contents: any[] = [];

  constructor(private contentService: ContentService) { }

  ngOnInit(): void {
    this.contents = this.contentService.getContent();
  }

}
