import { Component, OnInit } from '@angular/core';
import {Content} from '../../model/Content';
import {SnackBarService} from '../../../service/snack-bar.service';
import {ContentService} from '../../../service/content.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  contents: Content[];
  constructor(private snackbarService: SnackBarService, private contentService: ContentService) { }

  ngOnInit(): void {
    this.contents = this.contentService.getContent();
    this.snackbarService.addSnack('Willkomen bei Flucht Software', 'Schließen');
  }

}
