import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit {

  @ViewChild('video') videoElement: ElementRef;
  videoLen: any;
  constructor() { }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {
    this.videoLen = this.videoElement.nativeElement.offsetWidth;
    console.log(this.videoLen);
  }

  getVideoWidth(event: any): void {
    console.log(event);
  }


}
