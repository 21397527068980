<div class="vid-container" (resize)="getVideoWidth($event)">
  <video src="assets/video/banner-org.mp4"
         autoplay="autoplay"
         muted="muted"
         loop="loop"
         class="vid"
         #video
         (change)="video.play()"
         (canplay)="video.play()"
         (loadedmetadata)="video.muted = true">
  </video>
  <img class="logo" src="assets/logo/logo-white-small.png" alt="Logo der Firma Flucht Software">
</div>
