<div style="background-image: url('assets/img/footer/footer1.png'); margin-top: 10px">
  <div style="display: flex; justify-content: space-between; align-items: flex-end; height: 700px">
    <div style="margin-bottom: 20px; margin-left: 20px">
      <img style="max-height: 250px" src="../../../assets/logo/logo-black.png">
    </div>
    <div class="test" style="margin-bottom: 20px;margin-right: 20px; color: #1f262d">
      <h1 style="font-weight: bold">Kontakt:</h1>
      <h2 style="margin: 0">Lukas Flucht | Wirtschaftsinformatiker B.Sc</h2>
      <h2 style="margin: 0">Software-Entwicklung </h2>
      <h2 style="margin: 0">72076 Tübingen</h2>
      <h2 style="margin: 0">Lukas@Flucht.me</h2>
    </div>
  </div>
  <div>
    <a style="margin: 0px 0px 50px 50px" routerLink="impressum">Impressum</a>
  </div>
</div>
