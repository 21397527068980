<mat-toolbar color="primary">
  <button (click)="openSideNav()"
          mat-icon-button
          class="example-icon"
          aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>
<!--  <span>Flucht-Software</span>-->
  <span class="example-spacer"></span>
  <mat-slide-toggle [hidden]="true" (click)="setDarkMode(darkMode)" [(ngModel)]="darkMode"></mat-slide-toggle>
  <button (click)="saveToClipBoard()" mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>
