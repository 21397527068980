import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ThemeService} from '../../../service/theme.service';
import {SidenavService} from '../../../service/sidenav.service';
import {Clipboard} from '@angular/cdk/clipboard';
import {SnackBarService} from '../../../service/snack-bar.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
  darkMode: boolean;

  constructor(private themeService: ThemeService,
              private sidenavService: SidenavService,
              private clipBoard: Clipboard,
              private snackbarService: SnackBarService) {
    this.setDarkMode(true);
  }

  ngOnInit(): void {
  }

  setDarkMode(darkMode: boolean): void {
    this.themeService.setTheme(darkMode);
  }

  openSideNav(): void {
    this.sidenavService.toggle();
  }

  saveToClipBoard(): void {
    this.snackbarService.addSnack('Webiste Adresse gespeichert','schließen');
    this.clipBoard.copy('flucht.me');
  }
}
