<div style="display: flex; flex-wrap: wrap; justify-content: center; flex-direction: column; align-items: center">
  <div *ngFor="let content of contents">
    <mat-card class="example-card zoom-effect-small" style="margin-top: 10px">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image" matTooltip="Lukas Flucht | Softwareentwickler"></div>
        <mat-card-title>{{content.title}}</mat-card-title>
        <mat-card-subtitle>Technologie</mat-card-subtitle>
      </mat-card-header>
        <img mat-card-image [src]="content.photoUrl1" alt="">
      <mat-card-content>
        <p style="font-size: 16px;">{{content.content}}</p>
      </mat-card-content>
      <mat-card-actions>
        <!--    <button mat-button color="primary">OK</button>-->
        <!--    <button mat-stroked-button color="accent">Details</button>-->
        <div *ngIf="content.id == '5'" style="display: flex; justify-content: flex-end">
          <p style="margin-left: 50px"><a href="https://hotel.flucht.me" target="_blank">Demo</a></p>
          <p style="margin-left: 50px"><a href="https://hotel.flucht.me/adminpanel/login" target="_blank">Admin-Dashboard</a></p>
        </div>
      </mat-card-actions>
    </mat-card>
  </div>
</div>

