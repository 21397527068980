import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ContentService} from '../../../service/content.service';
import {Content} from '../../model/Content';

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  styleUrls: ['./content-detail.component.scss']
})
export class ContentDetailComponent implements OnInit {
  contentId: string;
  content: Content;
  constructor(private activatedRoute: ActivatedRoute,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private contentService: ContentService) { }

  ngOnInit(): void {
    this.content = this.contentService.getContentById(this.data);
    console.log(this.content);
    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params);
      this.contentId = params.contentId;
    });
  }

}
