<app-toolbar class="navbar navbar-default navbar-fixed-top"></app-toolbar>
<mat-drawer-container class="example-container" style="min-height: 100%">
  <mat-drawer #drawer mode="side">
    <div class="menu-item">
      <button mat-button style="height: 0; position: absolute;">
      </button>
    </div>
    <div class="menu-item">
      <button (click)="closeSidenav()" mat-button routerLink="/home">
        <mat-icon>home</mat-icon>
        <span>Home</span>
      </button>
    </div>
    <div class="menu-item">
      <button (click)="closeSidenav()" mat-button routerLink="/impressum">
        <mat-icon>business</mat-icon>
        <span>Impressum</span>
      </button>
    </div>
<!--    <div class="menu-item">-->
<!--      <button (click)="closeSidenav()" mat-button routerLink="/translation">-->
<!--        <mat-icon>language</mat-icon>-->
<!--        <span>Translation</span>-->
<!--      </button>-->
<!--    </div>-->
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </mat-drawer-content>

</mat-drawer-container>

