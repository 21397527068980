import { Injectable } from '@angular/core';
import {OverlayContainer} from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  constructor(private overlay: OverlayContainer) {}

  setTheme(darkMode: boolean): void {
    sessionStorage.setItem('theme', String(darkMode));
    if (darkMode) {
      this.overlay.getContainerElement().classList.remove('light-theme');
      this.overlay.getContainerElement().classList.add('dark-theme');
      document.body.classList.remove('light-theme');
      document.body.classList.add('dark-theme');
      this.overlay.getContainerElement().classList.add('dark-cal');
      document.body.classList.add('dark-cal');
    } else {
      this.overlay.getContainerElement().classList.remove('dark-theme');
      this.overlay.getContainerElement().classList.add('light-theme');
      document.body.classList.remove('dark-theme');
      document.body.classList.add('light-theme');
      this.overlay.getContainerElement().classList.remove('dark-cal');
      document.body.classList.remove('dark-cal');
    }
  }

}
