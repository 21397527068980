import { Injectable } from '@angular/core';
import {Content} from '../app/model/Content';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  contents: Content[]  = [{
    id: '0',
    title: 'Frontend',
    content: 'Flucht Softwareentwicklung ist ein Unternehmen, das sich auf die Entwicklung von Angular-Anwendungen spezialisiert hat. Unser Ziel ist es, unseren Kunden eine ' +
      'bestmögliche User Experience (UX) zu bieten. Unser Team verfügt über umfangreiche Erfahrung in der Entwicklung von Angular-Anwendungen. Wir kombinieren modernste Technologien ' +
      'mit innovativen Methoden, um maßgeschneiderte Lösungen zu entwickeln, die unseren Kunden helfen, ihre Ziele zu erreichen. Wir verwenden fortschrittliche Technologien, um ' +
      'zuverlässige Anwendungen zu erstellen, die schneller, sicherer und kosteneffizienter sind. Wir verfügen über ein tiefes Verständnis der Benutzerbedürfnisse und der ' +
      'verschiedenen Plattformen, um ein optimales Ergebnis zu erzielen. Wir bieten unseren Kunden ein breites Spektrum an Dienstleistungen, von der Entwicklung von ' +
      'benutzerfreundlichen und individuellen Web-Anwendungen über die Entwicklung von mobilen Anwendungen bis hin zur Entwicklung von Backend-APIs. Wir sind stolz darauf, ' +
      'benutzerfreundliche und leistungsstarke Anwendungen zu entwickeln, die unseren Kunden helfen, effizienter zu arbeiten und ihre eigenen Ziele zu erreichen.' +
      'Wir entwickeln auch Ionic-Anwendungen, um die Verwendung von Apps auf mobilen Geräten zu ermöglichen. Ionic bietet eine Reihe von Funktionen, die es Ihnen ermöglichen,' +
      ' benutzerfreundliche und leistungsstarke Anwendungen zu erstellen. Mit Ionic können Sie benutzerfreundliche und ansprechende Benutzeroberflächen erstellen, die auf jeder ' +
      'beliebigen Plattform funktionieren. Wir verwenden modernste Technologien, damit Ihre Anwendungen auf allen gängigen Plattformen wie iOS und Android reibungslos laufen. ' +
      'Unser Team verfügt über die nötige Erfahrung, um Ihnen eine einzigartige Anwendung zu entwickeln, die Ihnen hilft, Ihre Ziele zu erreichen. Wir bieten auch Beratungsleistungen an, ' +
      'um sicherzustellen, dass Ihre Anwendungen auf den neuesten Technologien basieren, damit sie schneller, sicherer und kosteneffizienter sind.',
    contentType: '1',
    photoUrl1: '/assets/img/it/crop/it-1-edit-right-4.jpg'
  }, {
    id: '1',
    title: 'Backend',
    content: 'Wir verwenden modernste Technologien, um Ihre Daten sicher zu verwalten und zu' +
      ' schützen. Unsere Anwendungen sind für alle gängigen Betriebssysteme und Geräte verfügbar.' +
    'Unser Springboot-Framework ist der Schlüssel zu einer nahtlosen Erfahrung. Es ermöglicht uns, schnelle und dynamische Anwendungen zu entwickeln, die auf modernsten Technologien basieren. Mit unserer robusten und skalierbaren Architektur können ' +
      'Sie eine einheitliche Benutzererfahrung auf jedem Gerät und Betriebssystem genießen. ' +
      'Unser Team steht Ihnen jederzeit zur Seite, um Ihnen bei der Entwicklung und dem Schutz Ihrer Daten zu helfen. Wir sind jederzeit bereit, Ihre Anforderungen zu erfüllen und Ihnen die bestmögliche Lösung zu bieten.' ,
    contentType: '1',
    photoUrl1: '/assets/img/it/crop/it-2-edit.jpg'
  }, {
    id: '2',
    title: 'Datenbanken',
    content:  'Unser Online-Auftritt bietet Ihnen eine vollständige, zuverlässige und skalierbare Applikation, die auf Docker und Kubernetes basiert und mit Relationalen Datenbanksystemen veröffentlicht wird. Mit ' +
      'dieser Technologie können Sie Ihre Anwendungen und Daten schnell und einfach auf verschiedene Umgebungen hochladen, skalieren und verwalten. Dank Docker und Kubernetes ist die Anwendung l' +
      'eicht zu warten, zu überwachen und zu skalieren, ohne dass man sich Sorgen um die Stabilität oder Sicherheit machen muss. Außerdem kann die Anwendung ' +
      'schnell und einfach aktualisiert werden, da alle Anpassungen automatisch in der Anwendung übernommen werden. MariaDB ist eine leistungsstarke, zuverlässige und sichere Datenbank, ' +
   'die eine einfache und doch leistungsstarke Datenbankverwaltung bietet. Mit MariaDB können Sie schnell und einfach Datenbanken erstellen, verwalten und skalieren, um Ihren Anforderungen gerecht zu werden.',
    contentType: '1',
    photoUrl1: '/assets/img/it/crop/it-3-edit.jpg'
  }, {
    id: '3',
    title: 'Produkte : CMS : UI',
    content:  'Die moderne Website des CMS-Systems ist eine intuitive und benutzerfreundliche Plattform, die Benutzern eine schnelle und bequeme Möglichkeit ' +
      'bietet, ihre Websites zu erstellen und zu verwalten. Die Website ist einfach, intuitiv und anpassbar und bietet eine große Auswahl an Funktionen wie eine einfache Drag-and-Drop-Schnittstelle, ' +
      'die es Benutzern ermöglicht, Inhalte einfach zu erstellen und zu bearbeiten, eine integrierte Suchmaschine und eine intuitive Benutzeroberfläche, die einfache Navigation und Benutzererfahrung ' +
      'bietet. Darüber hinaus bietet Flucht Softwareentwicklung eine Vielzahl von Plugins, die Benutzern dabei helfen, ihre Websites zu erweitern und anzupassen.' +
      ' Darüber hinaus bietet Flucht Softwareentwicklung auch einen umfassenden Kunden- und technischen Support, der Benutzern hilft, ihre Websites schnell und effizient zu verwalten.',
    contentType: '1',
      photoUrl1: '/assets/img/screen1.jpg'
  },
    {
    id: '4',
    title: 'Produkte : CMS : Adminpanel',
    content: 'Die Bedienung des CMS-Systems für Hotels, bei dem die Inhalte dynamisch über ein Admin-Panel konfiguriert werden können, beginnt mit der Anmeldung im Admin-Panel. Sobald Sie ' +
      'eingeloggt sind, können Sie Inhalte wie Fotos, Videos, Texte, Preise und andere Informationen hinzufügen, bearbeiten, löschen und ' +
      'organisieren. Sie können auch eine Suchfunktion hinzufügen, um Besuchern zu ermöglichen, Informationen zu finden.' +
      'Sie können auch ein System zur Verwaltung von Buchungen implementieren, um zu verhindern, dass mehrere Gäste dasselbe Zimmer buchen. Darüber hinaus können Sie eine Reihe von ' +
      'Preisfiltern implementieren, um Gästen eine Auswahl an verschiedenen Zimmerpreisen anzubieten.' +
      'Sobald Sie die Inhalte hinzugefügt haben, müssen Sie das CMS-System mit Ihrer Website verknüpfen. Dies ermöglicht es Ihnen, die Inhalte auf Ihrer Website anzuzeigen. Sie können ' +
      'dann das Design Ihrer Website anpassen, um sicherzustellen, dass sie mit dem CMS-System kompatibel ist.' +
      'Sobald die Website online ist, können Sie die Website-Statistiken überwachen, um zu sehen, wie viele Besucher die Seite besuchen und wie sie sich verhalten. Dies ermöglicht es ' +
      'Ihnen, die Inhalte und Funktionen Ihrer Website zu verbessern, um Besuchern ein besseres Erlebnis zu bieten.' +
      'Abschließend können Sie auch Benachrichtigungen einrichten, um sicherzustellen, dass alle Gäste über neue Angebote und Aktionen informiert werden.',
    contentType: '1',
    photoUrl1: '/assets/img/screen2.jpg'
  },
    {
      id: '5',
      title: 'Demo',
      content: 'Hier geht es zur Demo. ' +
        'Für Zugänge des Admin Dashboards oder eine eigene Demo Version, bitte eine E-Mail mit dem Betreff Login oder Demo an Lukas@flucht.me ',
      contentType: '1',
      photoUrl1: ''
    }
  ];
  constructor() { }

  getContent(): Content[] {
    return this.contents;
  }

  getContentById(id: string) {
    return this.contents[id];
  }
}
