import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {ImpressumComponent} from './pages/impressum/impressum.component';
import {TranslationComponent} from "./pages/translation/translation.component";

const routes: Routes = [
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent, title: 'Flucht Software | Home'},
  {path: 'impressum', component: ImpressumComponent, title: 'Flucht Software | Impressum'},
  {path: 'translation', component: TranslationComponent, title: 'Flucht Software | tr'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
