import { Injectable } from '@angular/core';
import {Content} from '../app/model/Content';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  contents: Content[]  = [
   {
    id: '3',
    title: 'Produkte : CMS : UI',
    content:  'Die moderne Website des CMS-Systems ist eine intuitive und benutzerfreundliche Plattform, die Benutzern eine schnelle und bequeme Möglichkeit ' +
      'bietet, ihre Websites zu erstellen und zu verwalten. Die Website ist einfach, intuitiv und anpassbar und bietet eine große Auswahl an Funktionen wie eine einfache Drag-and-Drop-Schnittstelle, ' +
      'die es Benutzern ermöglicht, Inhalte einfach zu erstellen und zu bearbeiten, eine integrierte Suchmaschine und eine intuitive Benutzeroberfläche, die einfache Navigation und Benutzererfahrung ' +
      'bietet. Darüber hinaus bietet Flucht Softwareentwicklung eine Vielzahl von Plugins, die Benutzern dabei helfen, ihre Websites zu erweitern und anzupassen.' +
      ' Darüber hinaus bietet Flucht Softwareentwicklung auch einen umfassenden Kunden- und technischen Support, der Benutzern hilft, ihre Websites schnell und effizient zu verwalten.',
    contentType: '1',
      photoUrl1: '/assets/img/screen1.jpg'
  },
    {
    id: '4',
    title: 'Produkte : CMS : Adminpanel',
    content: 'Die Bedienung des CMS-Systems für Hotels, bei dem die Inhalte dynamisch über ein Admin-Panel konfiguriert werden können, beginnt mit der Anmeldung im Admin-Panel. Sobald Sie ' +
      'eingeloggt sind, können Sie Inhalte wie Fotos, Videos, Texte, Preise und andere Informationen hinzufügen, bearbeiten, löschen und ' +
      'organisieren. Sie können auch eine Suchfunktion hinzufügen, um Besuchern zu ermöglichen, Informationen zu finden.' +
      'Sie können auch ein System zur Verwaltung von Buchungen implementieren, um zu verhindern, dass mehrere Gäste dasselbe Zimmer buchen. Darüber hinaus können Sie eine Reihe von ' +
      'Preisfiltern implementieren, um Gästen eine Auswahl an verschiedenen Zimmerpreisen anzubieten.' +
      'Sobald Sie die Inhalte hinzugefügt haben, müssen Sie das CMS-System mit Ihrer Website verknüpfen. Dies ermöglicht es Ihnen, die Inhalte auf Ihrer Website anzuzeigen. Sie können ' +
      'dann das Design Ihrer Website anpassen, um sicherzustellen, dass sie mit dem CMS-System kompatibel ist.' +
      'Sobald die Website online ist, können Sie die Website-Statistiken überwachen, um zu sehen, wie viele Besucher die Seite besuchen und wie sie sich verhalten. Dies ermöglicht es ' +
      'Ihnen, die Inhalte und Funktionen Ihrer Website zu verbessern, um Besuchern ein besseres Erlebnis zu bieten.' +
      'Abschließend können Sie auch Benachrichtigungen einrichten, um sicherzustellen, dass alle Gäste über neue Angebote und Aktionen informiert werden.',
    contentType: '1',
    photoUrl1: '/assets/img/screen2.jpg'
  },
    {
      id: '5',
      title: 'Demo',
      content: 'Hier geht es zur Demo. ' +
        'Für Zugänge des Admin Dashboards oder eine eigene Demo Version, bitte eine E-Mail mit dem Betreff Login oder Demo an Lukas@flucht.me ',
      contentType: '1',
      photoUrl1: ''
    }
  ];
  constructor() { }

  getContent(): Content[] {
    return this.contents;
  }

  getContentById(id: string): any {
    return this.contents[id];
  }
}
