// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyCzIdrXNBZ1PZxfM7iP5koVFMB9_457iv4',
    authDomain: 'flucht-software.firebaseapp.com',
    projectId: 'flucht-software',
    storageBucket: 'flucht-software.appspot.com',
    messagingSenderId: '794249427234',
    appId: '1:794249427234:web:95b8fb632c5d66a9294d7b',
    measurementId: 'G-L5VDSGGGBW'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
