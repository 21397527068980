import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Content} from '../../model/Content';
import {MatDialog} from '@angular/material/dialog';
import {ContentDetailComponent} from '../content-detail/content-detail.component';
import {SnackBarService} from "../../../service/snack-bar.service";
import {ContentService} from "../../../service/content.service";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContentComponent implements OnInit {
  contents: Content[];

  constructor(private dialog: MatDialog,
              private contentService: ContentService) {
  }

  ngOnInit(): void {
    this.contents = this.contentService.getContent();
  }

  openDialogDetails(contentId: string) {
    this.dialog.open(ContentDetailComponent, {data: contentId}).afterClosed().subscribe(res => {
      console.log(res);
    });
  }



}
