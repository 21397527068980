<div class="container">
  <h1>Impressum</h1>
  <div class="section">
    <h2>Angaben gemäß § 5 TMG</h2>
    <p>
      Lukas Flucht
      <br>
      Wirtschaftsinformatiker B.Sc
      <br>
      Quenstedtstr. 28
      <br>
      72076 Tübingen
    </p>
  </div>

  <div class="section">
    <h2>Kontakt</h2>
    <p>
      Telefon: +49 1752507994
      <br>
      E-Mail: lukas@flucht.me
      <br>
      web: www.flucht.me
    </p>
  </div>

  <div class="section">
    <h2>Berufsbezeichnung</h2>
    <p>
      Software-Entwickler
    </p>
  </div>

  <div class="section">
    <h2>Umsatzsteuer-ID</h2>
    <p>Nicht vorhanden da es sich um eine Nebenberuflichetätigkeit handelt.</p>
  </div>

  <div class="section">
    <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
    <p>
      Lukas Flucht
      <br>
      Quenstedtstr. 28
      <br>
      72076 Tübingen
    </p>
  </div>

</div>
